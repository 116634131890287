/* Styles for mobile */

@media (max-width: 768px) {
  #root {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(-90deg, #811f1b 85%, #811f1b);
  }
  
  * {
    user-select: none;
  }
  

  #root>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;  /* Change this line from center to flex-start */
    align-items: center;
    margin: 0px;
  }
  
  .app {
    overflow: hidden;
  }
  
  .app>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .row {
    flex-direction: row !important;
  }
  
  .row>* {
    margin: 5px;
  }

  h1 {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    text-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.30);
    margin-top: 10px;
    margin-bottom: 0px;
    max-width: 90%;
    height: 8%;
  }
  
  h2 {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    margin-top: 2%;
    margin-bottom: 0px;
    max-width: 90%;
    height: 4%;
  }
  
  .flags {
    position: relative;
    background-color: #fff;
    width: 80%;
    height: 8%;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    margin-top: 10%;
    margin-bottom: 4%;
  
  }
  
  .title {
    width: 90%;
  }
  
  .swipe {
    position: absolute;
  }
  
  .share_buttons {
    display: flex;
    justify-content: space-between;
    /* Optional: if you want to space buttons evenly */
  }
  

  .cardContainer {
    width: 90vw;
    max-width: 270px;
    height: 360px;
    margin: 10px;
  }

  .card {
    position: relative;
    background-color: #fff;
    width: 90vw;
    max-width: 270px;
    height: 360px;
    box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.30);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }

  
  .infoText {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 2%;
    display: flex;
    flex-wrap: nowrap; /* Prevents the buttons from wrapping to the next line */
    align-items: center;
    flex-direction: row;

    justify-content: center;
  }
  
  .swipeButton {
    height: 90px; /* Adjust based on your preference, or omit if you want natural height */
    display: flex; /* This helps center the image inside the button */
    align-items: center; /* This centers the image vertically */
    justify-content: center; /* This centers the image horizontally */
    overflow: hidden; /* This ensures oversized images don't spill out of the button */  }
  
  .swipeButton img {
    max-width: 100%; /* This ensures image doesn't exceed button */
    max-height: 100%; /* Keeps image within button */
  }

  
  .buttons button {
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    transition: 200ms;
    margin: 10px;
    font-weight: bolder;
    width: 160px;
    background-color: #f3f3f386;
    color: #fff;
  }
  
  .precentage {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
    margin-top: 2%;
    margin-bottom: 0px;
    max-width: 100%;
    height: 8%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 40px; 
  }

  .buttons button:hover {
    transform: scale(1.05);
  }
  
  Dialog {
    align-items: center;
    position: relative;
  }

  .dialog_image_div{

    max-height: 570px;

  }
  .replay-button {
    border: none;
    background-color: transparent;
    width: 140px;
    padding: 10px 0px;
    font-size: 16px; /* Adjust if needed */
    cursor: pointer; /* Mouse cursor will change to hand icon when hovering */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.replay-button:hover {
    background-color: #2980b9; /* Darker blue on hover */
    color: #ffffff; /* White text on hover */
}

.replay-button:focus {
    outline: none; /* Remove focus outline */
}
  
  .dialog_image {
    position: relative;
    background-color: #fff;
    max-width: 100%;
    max-height: 550px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  
  }

  
  .MuiDialog-paperScrollPaper,
  .MuiDialogTitle-root {
    align-items: center;
    padding-top: 0px;
    padding: 5px 0px !important;

  }

  
  .dialog-title {
    text-align: center;
    height: 20%;
  
  }
  
  .further-reading {
    color: #fff;
    }
    
    a.further-reading{
      text-decoration: underline
    }

    .card p {
      color: #811f1b !important;
      font-weight: bolder !important;
      position: absolute;  /* Set the paragraph's position to absolute */
      top: 10px;           /* Adjust this value to set the distance from the top */
      right: 10px;         /* Adjust this value to set the distance from the right */
      margin: 0;           /* Remove default margins from the paragraph */
      /* You can add additional styling like font-weight, font-size, etc., as per your design needs */
  }
}


/* Styles for desktops and larger devices */


@media (min-width: 769px) {
  body {
    background-color: lightblue;
  }

  #root {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    /* background: linear-gradient(#fff, #999); */
    background: linear-gradient(-90deg, #811f1b 85%, #811f1b);
  }
  
  * {
    user-select: none;
  }
  
  #root>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app {
    overflow: hidden;
  }
  
  .app>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .row {
    flex-direction: row !important;
  }
  
  .row>* {
    margin: 5px;
  }
  
  h1 {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
    margin-top: 2%;
    margin-bottom: 0px;
    max-width: 90%;
    height: 8%;
  }
  
  h2 {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    margin-top: 2%;
    margin-bottom: 0px;
    max-width: 90%;
    height: 4%;
  }
  
  .flags {
    position: relative;
    background-color: #fff;
    width: 90%;
    height: 8%;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    margin-top: 2%;
    margin-bottom: 4%;
  
  }
  
  .title {
    width: 70%;
  }


  
  .swipe {
    position: absolute;
  }
  
  .cardContainer {
    width: 90vw;
    max-width: 330px;
    height: 440px;
  }
  
  
  .card {
    position: relative;
    background-color: #fff;
    width: 90vw;
    max-width: 330px;
    height: 440px;
    box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.30);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .swipeButton {
    height: 90px; /* Adjust based on your preference, or omit if you want natural height */
    display: flex; /* This helps center the image inside the button */
    align-items: center; /* This centers the image vertically */
    justify-content: center; /* This centers the image horizontally */
    overflow: hidden; /* This ensures oversized images don't spill out of the button */  }
  
  .swipeButton img {
    max-width: 100%; /* This ensures image doesn't exceed button */
    max-height: 100%; /* Keeps image within button */
  }

  .share_buttons {
    display: flex;
    justify-content: space-between;
    /* Optional: if you want to space buttons evenly */
  }

  .cardContent {
    width: 100%;
    height: 100%;
  }
  
  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }
  
  .infoText {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 2%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: '20%';
  }
  
  .buttons button {
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #f3f3f383;
    transition: 200ms;
    margin: 10px;
    font-weight: bolder;
    width: 160px;
  }
  
  .buttons button:hover {
    transform: scale(1.05);
  }
  
  Dialog {
    align-items: center;
    position: relative;
  }
  
  .dialog_image_div{

    max-height: 580px;

}


.replay-button {
  border: none;
  background-color: transparent;
  width: 160px;
  padding: 10px 0px;
  font-size: 16px; /* Adjust if needed */
  cursor: pointer; /* Mouse cursor will change to hand icon when hovering */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.replay-button:hover {
  background-color: #2980b9; /* Darker blue on hover */
  color: #ffffff; /* White text on hover */
}

.replay-button:focus {
  outline: none; /* Remove focus outline */
}

  .dialog_image {
    position: relative;
    background-color: #fff;
    max-width: 100%;
    max-height: 560px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;

  }

  .MuiDialog-paperScrollPaper,
  .MuiDialogTitle-root {
    align-items: center;
    padding-top: 0px;
    padding: 5px 0px !important;

  }

  .dialog-title {
    text-align: center;
    height: 20%;


  }

  .behind-cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

  }
  .precentage {
    font-family: 'Lucida Console', Monospace, Arial, sans-serif;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.30);
    margin-top: 2%;
    margin-bottom: 0px;
    max-width: 90%;
    height: 8%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 40px; 
  }

  .further-reading {
  color: #fff;
  }
  .card p {
    color: #811f1b !important;
    font-weight: bolder !important;
    position: absolute;  /* Set the paragraph's position to absolute */
    top: 10px;           /* Adjust this value to set the distance from the top */
    right: 10px;         /* Adjust this value to set the distance from the right */
    margin: 0;           /* Remove default margins from the paragraph */
    /* You can add additional styling like font-weight, font-size, etc., as per your design needs */
}
}
  